import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Alert } from 'reactstrap';
import { connect } from "react-redux";
import moment from "moment";
import { MapKey } from "../../../constants/Constants";
import { getEquipmentDetails, getWorkDoneDetails, getYearOrMonth } from "../../../api/service-provider/equipments";
import locationPointer from '../../../assets/images/my-equipment-page-icons/location-pointer.png';
import maplocationPointer from '../../../assets/images/my-equipment-page-icons/tractorlocation.png';
import Report from "../../customer/daily-work/elements/ReportComponent";
import MachineReport from "../../customer/daily-work/elements/MachineReportComponent";
import MapBox from '../../../components/shared/MapBox';
import Map from "../../../components/shared/Map";
import Image from "../../../components/shared/Image";
import { setLoader, setConfirmBox, setToastr } from "../../../actions/Application";
import Chart from "../../customer/daily-work/elements/ChartComponent";
import { setEquipmentDailywork } from "../../../actions/Dailywork";
import defaultImg from "../../../assets/images/noimage.png";
import Moment from "react-moment";
import "./style.scss";
import bellIcon from '../../../assets/images/my-equipment-page-icons/bell-black.png';
import { FaClock } from 'react-icons/fa';
import warn from '../../../assets/images/my-equipment-page-icons/warning.png';
import SelectField from '../../../components/shared/SelectField';

class EquipmentDetails extends Component {

    constructor(props) {
        super(props);
        console.log("++++++++++ EquipmentDetails ++++++++=");
        this.state = {
            equipment: {},
            isMarkerShown: false,
            lat: 0,
            lon: 0,
            routeDetail: [],
            performanceParams: {},
            group: '',
            currentDate: moment(),
            selectedDate: '',
            workDate: '',
            years: [],
            months: [],
            openToDate: undefined,
            data: {
                fromDate: moment().subtract(1, "d"),
                toDate: moment().subtract(1, "d"),
                year: "",
                month: '',
            },
        }

        this.getEquipmentDetails = this.getEquipmentDetails.bind(this);
        this.dataOnOtherDate = this.dataOnOtherDate.bind(this);
        //this.getWorkDoneDetails = this.getWorkDoneDetails.bind(this);
        this.getYearOrMonth = this.getYearOrMonth.bind(this);

    }


    /**
    * Get the new props and set into state if they are not same.
    * @param  { Object } nextProps Contains the next props
    */
    componentWillReceiveProps(nextProps) {
        console.log("++++ componentWillReceiveProps ++ ");
        /*  if (this.props !== nextProps) {
             console.log(" +++++++ componentWillReceiveProps ++++"+JSON.stringify(nextProps))
         } */
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("shouldComponentUpdate");
        // fix for your update
        if (this.state.key !== nextProps.key) {

            return true;
        }
        return true;
    }

    componentDidMount() {

        console.log("***componentDidMount***");


        let { machineId, userId } = this.props.match.params, { push } = this.props.history;

        if (!machineId || !userId) push(`/service-provider/equipment-operating-info`);
        this.props.setLoader({ loader: { open: true } });

        this.getEquipmentDetails({
            machineSaleId: machineId,
            userId: userId,
            inDate: moment().format("YYYY-MM-DD")
        });
        this.getYearOrMonth();
        /*this.getWorkDoneDetails(
            {
                machineSaleId: machineId,
                userId: userId,
                inDate: moment().format("YYYY-MM-DD")
            }
        );*/

    }
    /*  static getDerivedStateFromProps(nextProps, prevState) {
         console.log(" +++++++ getDerivedStateFromProps nextProps++++"+JSON.stringify(nextProps))
     
       //  console.log(" +++++++ componentWillReceiveProps prevState++++"+JSON.stringify(prevState))
     
         if (nextProps.match.params.machineId !== prevState.equipment.machineNum){
             console.log(" +++++++ getDerivedStateFromProps matched ++++"+JSON.stringify(nextProps))
     
         
        }
        return null;
      }
  */
    getEquipmentDetails(data) {
        this.setState({ selectedDate: data.inDate })
        let { push } = this.props.history, { setLoader, setToastr } = this.props;
        getEquipmentDetails(data)
            .then(response => {
                setLoader({ loader: { open: false } });

                if (response.data.performanceParams) {
                    this.setState({ performanceParams: response.data.performanceParams });
                }

                if (!response.data.equipOperatingInfo) {

                    if (Object.keys(this.state.equipment).length && response.data.routeDetail) {
                        this.setState({ routeDetail: response.data.routeDetail || [] });
                    } else {
                        setToastr({ toastr: { message: "No data available.", type: "info" } });
                    }

                    //if(Object.keys(this.state.equipment).length === 0) push(`/service-provider/equipment-operating-info`);
                } else {
                    if (Object.keys(response.data.equipOperatingInfo).length > 0) {
                        /** dispatch an action for share data with child components */
                        this.props.setEquipmentDailywork(response.data);
                        this.setState({ group: response.data.status })
                        this.setState({ equipment: response.data.equipOperatingInfo, routeDetail: response.data.routeDetail || [] });
                    }
                }
            })
            .catch(error => {
                setLoader({ loader: { open: false } });
                setToastr({ toastr: { message: "This link is not valid.", type: "info" } })
            });
    }

    loadMap(e) {
        e.preventDefault();
        let lat = parseFloat(e.target.parentElement.getAttribute('lat'));
        let lon = parseFloat(e.target.parentElement.getAttribute('lon'));
        this.setState({ lat, lon, isMarkerShown: true })
    }

    dataOnOtherDate(date = null) {
        let { machineId, userId } = this.props.match.params;
        this.props.setLoader({ loader: { open: true } });
        setTimeout(() => {
            this.getEquipmentDetails({
                machineSaleId: machineId,
                userId: userId,
                inDate: moment(date).format("YYYY-MM-DD")
            });
        }, 400);
        /*this.getWorkDoneDetails(
            {
                machineSaleId: machineId,
                userId: userId,
                inDate: moment().format("YYYY-MM-DD")
            }
        );*/
    }

    /*getWorkDoneDetails(data){
        //let {push} = this.props.history, {setLoader, setToastr} = this.props;
        let workDate=this.state.workDate;
        getWorkDoneDetails(data)
        .then(response => {
            //setLoader({ loader: {open: false}});
            if(response.data.workDoneDate){
            console.log('response'+JSON.stringify(response.data.workDoneDate));
            this.setState({workDate : response.data.workDoneDate})
            console.log('workdone'+JSON.stringify(workDate))
            }
        })
    }*/

    getYear() {
        let options = [];
        let years = this.state.years;
        for (let i = 0; i < years.length; i++) {
            options.push(<option key={years[i]} value={years[i]}> {years[i]} </option>);
        }
        return { year: options }
    }

    getMonth() {
        let options = [];
        let months = this.state.months;
        let monthMap = {
            "1": "JAN",
            "2": "FEB",
            "3": "MAR",
            "4": "APR",
            "5": "MAY",
            "6": "JUN",
            "7": "JUL",
            "8": "AUG",
            "9": "SEP",
            "10": "OCT",
            "11": "NOV",
            "12": "DEC"
        }
        for (let i = 0; i < months.length; i++) {
            options.push(<option key={months[i]} value={months[i]}> {monthMap[months[i]]} </option>);
        }
        return { month: options }
    }

    getYearOrMonth(year) {
        let { machineId } = this.props.match.params
        this.props.setLoader({ loader: { open: true } });
        let req = {
            "machineSaleId": machineId
        }
        if (year) {
            req["qs"] = `year=${year}`;
        }
        getYearOrMonth(req)
            .then(result => {
                if (result.data && result.data.length > 0) {
                    let yearMonth = result.data;
                    if (year) {
                        let extractedMonths = yearMonth.map(i => i.month);
                        this.setState({ months: extractedMonths });
                    }
                    else {
                        let extractedYears = yearMonth.map(i => i.year);
                        this.setState({ years: extractedYears });
                    }
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });
        if (type == 'year' && !isNaN(data)) {
            this.getYearOrMonth(data);
            let openToDate = moment().year(data);
            this.setState({ openToDate: openToDate })
            this.dataOnOtherDate(openToDate);
        } else if (type == 'year' && isNaN(data)) {
            const d = new Date();
            let month = this.state.data.month;
            let currentYear = d.getFullYear();
            let openToDate = moment().year(currentYear);
            this.setState({ openToDate: openToDate });
            this.setState(
                {
                    data: {
                        ...this.state.data, month: '', year: ''
                    }
                });
                this.dataOnOtherDate(openToDate);
        }
        if (type == 'month' && !isNaN(data)) {
            let selectedMonth = data - 1;
            let openToDate = moment().year(this.state.data.year).month(selectedMonth);
            this.setState({ openToDate: openToDate })
            this.dataOnOtherDate(openToDate);
        }
        else if (type == 'month' && isNaN(data)) {
            const d = new Date();
            let year = this.state.data.year;
            let currentMonth = d.getMonth();
            let openToDate =  moment().year(this.state.data.year).month(currentMonth);
            this.setState({ openToDate: openToDate });
            this.setState(
                {
                    data: {
                        ...this.state.data, month: '', year: this.state.data.year
                    }
                });
                this.dataOnOtherDate(openToDate);
        }
    }


    render() {
        let { equipment, isMarkerShown, lat, lon, routeDetail, performanceParams, group, selectedDate } = this.state, { translate } = this.props;
        let isService = true;
        let operatingHours = performanceParams.operatingHours;
        var expiryDate = moment(equipment.contractExpireDate)
        var now = moment()
        var isExpired = now.isAfter(expiryDate);
        console.log(isExpired, now, expiryDate)
        var warning = expiryDate.diff(now, 'months') <= 3 && !isExpired // not expired but due to expire
        const contractPeriod = (equipment.contractExpireDate)
        const currentDate = (this.state.currentDate);
        const date1 = new Date(contractPeriod);
        const todayDate = new Date(currentDate);
        var daysDifference = (date1.getTime() - todayDate.getTime()) / ((1000 * 60 * 60 * 24)) < 90 ? true : false;

        return (

            <Fragment>

                {isMarkerShown && <MapBox
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MapKey}`}
                    defaultZoom={15}
                    isMarkerShown
                    lat={lat}
                    lon={lon}
                    icon={maplocationPointer}
                    showMap={() => this.setState({ isMarkerShown: false })}
                    containerElement={<div className="popupUI" />}
                    loadingElement={<div className="popupUI" />}
                    mapElement={<div style={{ height: `100%` }} />}
                />}

                <Row className="toprow">
                    <Col lg="12" className='sp-topRow'>
                        {isExpired && <Alert color='dark' isOpen={true}><img src={bellIcon} alt="bell" className="exclamationt" />  {"Contract has been expired"} <p className='float-right'> <span className='ico-fix'><FaClock className='clocker' /></span>
                            {(equipment.contractExpireDate &&
                                <Moment format="D MMM YYYY">
                                    {equipment.contractExpireDate}
                                </Moment>) || " "}
                        </p></Alert>}
                        {warning && <Alert color='warning' isOpen={true}><img src={warn} alt="bell" className="exclamationt" />  {"Contract is due to expire"} <p className='float-right'> <span className='ico-fix'><FaClock className='clocker' /></span>
                            {(equipment.contractExpireDate &&
                                <Moment format="D MMM YYYY">
                                    {equipment.contractExpireDate}
                                </Moment>) || " "}
                        </p></Alert>}
                    </Col>
                </Row>
                <section className='sp-equipment-detail'>
                    <p class="ridge">
                        {/*  <h3 className='sub-heading u_l'>Equipment Operating Info - {equipment.numberPlate}</h3> */}
                        <p className='grey-title'>Equipment Info</p>
                        <div className="toprow row" style={{ marginBottom: '10px' ,marginLeft:'6px'}}>
                            <div className="text-center col-sm-4 col-lg-2">
                                <Image className="imagetractor fix-h" alt="logo" src={equipment.logo || defaultImg} style={{ height: '100px'}} />
                                {group == 'TE' && <div className="tractorDetail">{equipment.numberPlate ? equipment.numberPlate.toUpperCase() : " "}</div>}
                            </div>

                            <div className="col-sm-8 col-lg-10 eqdetails">
                                <div className="row">
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7'>Location</span>
                                                <span className='float-right col-sm-5'>

                                                    {(equipment.latitude && equipment.longitude) ? <a className="mappointer pointer" onClick={this.loadMap.bind(this)} lat={equipment.latitude || 0} lon={equipment.longitude || 0}>
                                                        <img src={locationPointer} alt="poi" className="poi" style={{ width: "25px" }} />
                                                    </a> : "-"}

                                                </span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div >
                                                <span className='highlighted col-sm-7'>Status</span>
                                                <span className='float-right col-sm-5'>{equipment.operatingStatus || "-"}</span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7'>Hourmeter (Hrs)</span>
                                                <span className='float-right col-sm-5'>{equipment.totalOperatingHour ? parseFloat(equipment.totalOperatingHour).toFixed(1) : "-"}</span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div className='row' style={{ marginLeft: '0px' }}>
                                                <span className='highlighted col-sm-6'>Last Operated Date and Time</span>
                                                <span className='float-right col-sm-5' style={{ marginLeft: '15px' }}>{equipment.lastOperatingDateTime ? (<Moment format="DD MMM YYYY HH:mm:ss" tz="Asia/Kolkata">
                                                    {equipment.lastOperatingDateTime}
                                                </Moment>) : "-"}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="eqdetailbox col-sm-4">

                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7'>Model</span>
                                                <span className='float-right col-sm-5'>{equipment.model || "-"}</span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7'>Machine Number</span>
                                                <span className='float-right col-sm-5'>{equipment.machineNum || " "}</span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7'>Type</span>
                                                <span className='float-right col-sm-5'>{equipment.eType ? equipment.eType.toUpperCase() : "-"}</span>
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div class='row'>
                                                <span className='highlighted col-sm-7'> Contract Date</span>
                                                <span className='col-sm-5'>

                                                    {equipment.contractDate ? (<Moment format="D MMM YYYY" tz="Asia/Kolkata">
                                                        {equipment.contractDate}
                                                    </Moment>) : " "}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="detailcover">
                                            <div class='row'>
                                                <span className='highlighted col-sm-7'>Contract Expiry</span>
                                                <span className='col-sm-5'>

                                                    {equipment.contractExpireDate ? (<Moment format="DD MMM YYYY" tz="Asia/Kolkata">
                                                        {equipment.contractExpireDate}
                                                    </Moment>) : " "}
                                                    {daysDifference &&
                                                        <a href="" onClick={() => this.props.history.push(`/service/contract-renew/${equipment.id}/${equipment.machineSaleId}`)}><span className="mappointer m-2">{translate('renew')}</span></a>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="detailcover">
                                            <div class='row'>
                                                <span className='highlighted col-sm-7'>Owner Name</span>
                                                <span className='float-right col-sm-5'>{equipment.ownerName}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="d-none col"><button type="submit" name="updateequipment" className="mr-5 btn-danger btn btn-primary">Save</button><button
                                        className="btn-dark btn btn-danger">Cancel</button></div>
                                </div>
                            </div>
                        </div>

                        <p className='grey-title'>Operating Info</p>
                        <div className="toprow row" style={{ marginBottom: '10px' }}>
                            <div className="col-sm-8 col-lg-12 eqdetails">
                                <div className="row">
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7' style={{marginRight:'50px'}}>Operated Time (HH:MM:SS)</span> 
                                                <span >{(operatingHours) || "0.0"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/*CHG-00020882 - working history changes starts*/}
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div class='row'>
                                                <span className='highlighted col-sm-7'>Working History(Year)</span>
                                                <span className='col-sm-5'>
                                                    {(this.state.years.length) > 0 ?
                                                        <SelectField
                                                            divClass="select"
                                                            type="select"
                                                            options={this.getYear().year}
                                                            value={(this.state.data.year.length) > 0 ? this.state.data.year : 'No working history'}
                                                            name="year"
                                                            //className={(this.state.yearError) ? 'border-danger' : ''}
                                                            handleChange={this.handleChange.bind(this)}
                                                            defaultOption="Select Year"
                                                        /> : 'No History Available'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div class='row'>
                                                {this.state.data.year && !isNaN(this.state.data.year) &&
                                                    <span className='highlighted col-sm-7'>Working History(Month)</span>
                                                }
                                                {this.state.data.year && !isNaN(this.state.data.year) &&
                                                    <span className='col-sm-5'>
                                                        <SelectField
                                                            divClass="select"
                                                            type="select"
                                                            options={this.getMonth().month}
                                                            value={this.state.data.month}
                                                            name="month"
                                                            //className={(this.state.yearError) ? 'border-danger' : ''}
                                                            handleChange={this.handleChange.bind(this)}
                                                            defaultOption="Select Month"
                                                        />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                {/*CHG-00020882 - working history changes ends*/}
                                </div>
                            </div>
                        </div>
                    </p>

                    {/* MachineReport Component Here */}
                    {group == 'CE' && (Object.keys(equipment).length > 0) && <MachineReport translate={translate} isEquipment={equipment ? true : false} isService={isService} />}


                    {equipment && Object.keys(equipment).length > 0 && <section>

                        <div className='relative'>
                            <Chart translate={translate} equipment={equipment} dataOnOtherDate={this.dataOnOtherDate} openToDate={this.state.openToDate} years={this.state.years} />
                            {equipment.operatingInfoResponse.length > 0 && <span className="route-map-btn" onClick={() => this.props.setConfirmBox({ modal: { open: true, header: true, size: "modal-lg", style: { "height": "400px" }, data: selectedDate, isServiceCall: true, content: <Map drivercoords={routeDetail} height="380px" width="96.5%" isRouteMap="yes" isMarkerShown="yes" /> } })}>View Route Map Details</span>}
                        </div>
                    </section>}

                    {group == 'CE' && (Object.keys(equipment).length > 0) && <Report translate={translate} isEquipment={equipment ? true : false} isService={isService} />}
                </section>

            </Fragment>
        );
    }
}

const mapDispatchToProps = { setLoader, setEquipmentDailywork, setConfirmBox, setToastr }

export default connect(null, mapDispatchToProps)(withRouter(EquipmentDetails));