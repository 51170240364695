/** This file will contain the global constants*/

/** @type { string } URI of the api */
export const imagesPath =  window.location.protocol + '//' +  window.location.host + '/../resources/assets/js/assets/image/';
export const webURL = window.location.protocol + '//' +  window.location.host;
//export const URL = 'deployment URL';

/** @type { String } key for encoding the data */
export const hashKey = '1QZqiCwwum4OfpvMmQ4TmiKD0';

export const iv = 'MmQ4TmiKD01QZqiCwwum4Ofpv';

/** @type { String } Google client Id */
export const googleClientId = '956485439947-bpdvhjdknkbp6nc0vac4cudb54g8v8l2.apps.googleusercontent.com';

export const authKey = 'TFs3bRZSsm';

export const MapKey = "AIzaSyC07gm8YtRyNMEd9uBsaLGKWQYHEwtG2-I";

export const GeoCodeKey = "AIzaSyB0LZV7xDfU8EL-h5XJFiZm65yQ2qOl0hY";

export const SSO = "https://sso.yanmar.co.in/sa-india?action=login";
//export const SSO = "https://sso.dev.yanmar.co.in/sa-india?action=login";

/** @type { string } URI of the api */

//ITs for Stage Env
export const URL =  'https://apistage.yanmar.co.in/customer/';

export const ADMINURL = 'https://apistage.yanmar.co.in/admin/';

export const SVPRURL = 'https://apistage.yanmar.co.in/sp/';


//ITs for Dev 
//export const URL =  'https://apidev.yanmar.co.in/customer/';

//export const ADMINURL = 'https://apidev.yanmar.co.in/admin/';

//export const SVPRURL = 'https://apidev.yanmar.co.in/sp/';

//export const URL =  'https://apistage.yanmar.co.in/customer/';

// export const ADMINURL = 'https://apistage.yanmar.co.in/admin/';

// export const SVPRURL = 'https://apistage.yanmar.co.in/sp/';

export const LIMIT = 15;

export const RealTimeAlertCall = 5 * 60 * 1000; //5 minutes

export const READER = 'READER';
export const FULL = 'FULL';
export const EDITOR = 'EDITOR';

